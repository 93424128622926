<div class="cuadro text-center">
    <div class="contenido">
        <div class="mt-3 mb-4">
            <img src="assets/img/logo.png" width="128">
        </div>
        <p>
            <i class="fa fa-spinner fa-spin fa-2x" style="color: #FFE81A"></i>
        </p>
        <p>{{ this.texto }}</p>
        <p>{{ this.textoSubtitulo }}</p>
    </div>
</div>