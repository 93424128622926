import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { CurrencyPipe, DatePipe, registerLocaleData } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';


import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';

import { AppComponent } from './app.component';

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
// IMPORTAR ANGULAR FIRE MESSAGING
import { FirebaseUIModule, firebase, firebaseui } from 'firebaseui-angular';
import { AgGridModule } from 'ag-grid-angular';
import { environment } from 'environments/environment';

import { InicioComponent } from './1_inicio/inicio.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { ComerciosComponent } from './5_comercios/comercios/comercios.component';
import { CadetesComponent } from './3_cadetes/cadetes/cadetes.component';
import { ProductosComponent } from './6_productos/productos/productos.component';
import { PedidosComponent } from './1_pedidos/pedidos.component';
import { UsuariosComponent } from './12_usuarios/usuarios/usuarios.component';
import { SelectorLocalidadComponent } from './components/selector-localidad/selector-localidad.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SelectorFechaComponent } from './components/selector-fecha/selector-fecha.component';
import { CrearComercioComponent } from './5_comercios/crear-comercio/crear-comercio.component';
import { LiquidacionClientesComponent } from './11_financiero/liquidacion-clientes/liquidacion-clientes.component';
import { LiquidacionComponent } from './11_financiero/liquidacion/liquidacion.component';
import { CuentasCorrientesComponent } from './11_financiero/cuentas-corrientes/cuentas-corrientes/cuentas-corrientes.component';
import { LegajoComponent } from './5_comercios/legajo/legajo.component';
import { CuentaCorrienteComercioComponent } from './11_financiero/cuentas-corrientes/cuenta-corriente-comercio/cuenta-corriente-comercio.component';
import { NuevoCadeteComponent } from './3_cadetes/nuevo-cadete/nuevo-cadete.component';
import { CadeteInfoComponent } from './3_cadetes/cadete-info/cadete-info.component';
import { CargandoComponent } from './components/cargando/cargando.component';
import { TurnosComponent } from './3_cadetes/turnos/turnos.component';
import { CategoriasComponent } from './4_categorias/categorias.component';
import { ComercioInfoComponent } from './5_comercios/comercio-info/comercio-info.component';
import { ImpositivoComercioComponent } from './5_comercios/impositivo-comercio/impositivo-comercio.component';
import { OperativoComercioComponent } from './5_comercios/operativo-comercio/operativo-comercio.component';
import { ProductosComercioComponent } from './5_comercios/productos-comercio/productos-comercio.component';
import { DepositosComercioComponent } from './5_comercios/depositos-comercio/depositos-comercio.component';
import { PromosComercioComponent } from './5_comercios/promos-comercio/promos-comercio.component';
import { SubirArchivoComponent } from './components/subir-archivo/subir-archivo.component';
import { BuscarComponent } from './components/buscar/buscar.component';
import { AgregarModificarProductoComponent } from './6_productos/agregar-modificar-producto/agregar-modificar-producto.component';
import { SelectorComercioComponent } from './components/selector-comercio/selector-comercio.component';
import { ModificarPreciosProductosComponent } from './5_comercios/modificar-precios-productos/modificar-precios-productos.component';
import { NuevaPromoComponent } from './7_promociones/nueva-promo/nueva-promo.component';
import { PromosComponent } from './7_promociones/promos/promos.component';
import { Select2Module } from 'ng-select2-component';
import { TurnosGeneralComponent } from './4_turnos/turnos-general.component';
import { LegajoUsuarioComponent } from './12_usuarios/legajo-usuario/legajo-usuario.component';
import { CrearUsuarioComponent } from './12_usuarios/crear-usuario/crear-usuario.component';
import { RendicionesComponent } from './10_centros-depositos/rendiciones.component';
import { NuevoAdminComercioComponent } from './5_comercios/nuevo-admin-comercio/nuevo-admin-comercio.component';
import { VerAdministradoresComponent } from './5_comercios/ver-administradores/ver-administradores.component';
import { VisualizarCompensacionComponent } from './11_financiero/visualizar-compensacion/visualizar-compensacion.component';
import { CuponesComponent } from './8_cupones/cupones.component';
import { ConfigDBComponent } from './13_config-db/config-db.component';
import { VisualizarLiquidacionComponent } from './11_financiero/visualizar-liquidacion/visualizar-liquidacion.component';
import { CustomTooltip } from './components/ag-grid-tooltip/ag-grid-tooltip.component';
import { ModalReciboComponent } from './components/modal-recibo/modal-recibo.component';

const firebaseUiAuthConfig: firebaseui.auth.Config = {
  signInFlow: 'redirect',
  signInOptions: [
    {
      provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      customParameters: {
        prompt: 'select_account'
      }
    }
  ],
  credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO
};

import localeEsAr from '@angular/common/locales/es-AR';
import { BannersComponent } from './9_banners/banners/banners.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { CompensacionesComponent } from './11_financiero/compensaciones/compensaciones.component';
import { ModalOrdenPagoComponent } from './components/modal-orden-pago/modal-orden-pago.component';
import { AdministradoresComponent } from './14_administradores/administradores/administradores.component';
import { LocalidadesComponent } from './1_localidades/localidades/localidades.component';
registerLocaleData(localeEsAr);

@NgModule({
  imports: [
    CommonModule,
    Select2Module,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ComponentsModule,
    RouterModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    FirebaseUIModule.forRoot(firebaseUiAuthConfig),
    NgbModule,
    AgGridModule,
    GoogleMapsModule,
    HttpClientModule,
    HttpClientJsonpModule
  ],
  declarations: [
    AppComponent,
    ConfigDBComponent,
    AdminLayoutComponent,
    ComerciosComponent,
    CadetesComponent,
    ProductosComponent,
    PedidosComponent,
    InicioComponent,
    UsuariosComponent,
    SelectorLocalidadComponent,
    SelectorFechaComponent,
    SelectorComercioComponent,
    SubirArchivoComponent,
    BuscarComponent,
    CrearComercioComponent,
    LiquidacionClientesComponent,
    LiquidacionComponent,
    CuentasCorrientesComponent,
    LegajoComponent,
    CuentaCorrienteComercioComponent,
    NuevoCadeteComponent,
    CadeteInfoComponent,
    CargandoComponent,
    TurnosComponent,
    ComercioInfoComponent,
    ImpositivoComercioComponent,
    OperativoComercioComponent,
    ProductosComercioComponent,
    DepositosComercioComponent,
    PromosComercioComponent,
    AgregarModificarProductoComponent,
    ModificarPreciosProductosComponent,
    NuevaPromoComponent,
    PromosComponent,
    TurnosGeneralComponent,
    LegajoUsuarioComponent,
    CrearUsuarioComponent,
    RendicionesComponent,
    NuevoAdminComercioComponent,
    VerAdministradoresComponent,
    VisualizarCompensacionComponent,
    CuponesComponent,
    VisualizarLiquidacionComponent,
    CustomTooltip,
    CategoriasComponent,
    BannersComponent,
    CompensacionesComponent,
    ModalReciboComponent,
    ModalOrdenPagoComponent,
    AdministradoresComponent,
    LocalidadesComponent
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es-AR' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'ARS' },
    DatePipe,
    CurrencyPipe
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
