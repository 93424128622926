import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FirestoreService } from 'app/services/firestore.service';
import { LocalidadService } from 'app/services/localidad.service';

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}
export const ROUTES: RouteInfo[] = [
  { path: '/inicio', title: 'Inicio', icon: 'flash_on', class: '' },
  { path: '/localidades', title: 'Localidades', icon: 'location_city', class: '' },
  { path: '/pedidos', title: 'Pedidos', icon: 'receipt', class: '' },
  { path: '/cadetes', title: 'Cadetes', icon: 'moped', class: '' },
  { path: '/turnos', title: 'Turnos', icon: 'access_time', class: '' },
  { path: '/categorias', title: 'Categorias', icon: 'category', class: '' },
  { path: '/comercios', title: 'Comercios', icon: 'storefront', class: '' },
  { path: '/productos', title: 'Productos', icon: 'fastfood', class: '' },
  { path: '/promos', title: 'Promociones', icon: 'loyalty', class: '' },
  { path: '/cupones', title: 'Cupones', icon: 'local_activity', class: '' },
  { path: '/banners', title: 'Banners', icon: 'view_carousel', class: '' },
  { path: '/rendiciones', title: 'Centros depósitos', icon: 'currency_exchange', class: '' },
  { path: '/compensaciones', title: 'Compensaciones', icon: 'attach_money', class: '' },
  { path: '/liquidaciones', title: 'Liquidaciones', icon: 'money_off', class: '' },
  { path: '/cuentas-corrientes', title: 'Cuentas Corrientes', icon: 'monetization_on', class: '' },
  { path: '/usuarios', title: 'Usuarios', icon: 'group', class: '' },
  { path: '/config-db', title: 'Config DB', icon: 'storage', class: '' },
  { path: '/administradores', title: 'Admins', icon: 'admin_panel_settings', class: '' }
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];
  permisosAdmin: string[] = [];

  constructor(
    private afAuth: AngularFireAuth,
    private firestoreService: FirestoreService,
    private localidadService: LocalidadService
  ) { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.permisosAdmin = Array.from(this.firestoreService.permisos);
    this.firestoreService.getHayComerciosCerrados(this.localidadService.localidad[0]).then((respuesta) => {
      if (respuesta) {
        this.menuItems.splice(6, 1, { path: '/comercios', title: 'Comercios', icon: 'storefront', class: 'text-danger' });
      }
    });
  }

  isMobileMenu() {
    if (window.innerWidth > 991) {
      return false;
    }
    return true;
  };

  async logOut() {
    if (confirm("Estás seguro de que querés cerrar la sesión?")) {
      await this.afAuth.signOut();
      location.reload();
    }
  }
}
