<div *ngIf="!this.loggedIn" class="container">
    <div class="mt-5">
        <firebase-ui></firebase-ui>
    </div>
</div>


<app-cargando *ngIf="this.cargando"></app-cargando>

<div *ngIf="!this.isAdmin && this.loggedIn" class="container-fluid">
    <h3> Acceso denegado. No sos administrador.</h3>
    <button class="btn btn-warning" (click)="this.logOut()">Cerrar sesión</button>
</div>

<div *ngIf="this.isAdmin">
    <router-outlet></router-outlet>
</div>