export const environment = {
  production: true,
  firebase: {
    apiKey: "AIzaSyDPsp-nytcElQjdvRHCwfViaakDqvSJDIM",
    authDomain: "admin.yendo.pro",
    databaseURL: "https://yendo-delivery-app.firebaseio.com",
    projectId: "yendo-delivery-app",
    storageBucket: "yendo-delivery-app.appspot.com",
    messagingSenderId: "191849644715",
    appId: "1:191849644715:web:bb8750eb88b8540b16edee",
    measurementId: "G-NHGC1215QE",
    vapidKey: "BE6oS_J94ATAC7-NH_vmR50juEpWWMPfdHGtLGtG_gfXYbpiME6PrUpAR_fpyBySx42CLaTP1_R5sRzAT4sB664"
  },
};
