<div class="logo">
    <div class="text-center">
        <img src="/assets/img/logo.png" style="width: 128px;" />
    </div>
</div>
<div class="sidebar-wrapper">
    <ul class="nav">
        <li routerLinkActive="active" *ngFor="let menuItem of menuItems" class="{{menuItem.class}} nav-item">
            <a class="nav-link" [routerLink]="[menuItem.path]"
                *ngIf="menuItem.path == '/inicio' || this.permisosAdmin.includes(menuItem.path.replace('/', '')) || this.permisosAdmin.includes('superAdmin')">
                <i class="material-icons">{{menuItem.icon}}</i>
                <p>{{menuItem.title}}</p>
            </a>
        </li>
        <li routerLinkActive="active" class="nav-item" style="cursor: pointer;">
            <a class="nav-link" (click)="this.logOut()">
                <i class="material-icons">exit_to_app</i>
                <p>Cerrar sesión</p>
            </a>
        </li>
    </ul>
</div>