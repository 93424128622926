import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentData, DocumentSnapshot, QueryDocumentSnapshot, QuerySnapshot } from '@angular/fire/compat/firestore';
import { Producto } from 'app/models/producto/producto.model';
import { Comercio } from 'app/models/comercio/comercio.model';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import * as firebase from 'firebase/compat/app';
import { FieldPath, increment, serverTimestamp } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class FirestoreService {

  uid: string;
  permisos: string[] = [];

  constructor(
    private cloudFunctions: AngularFireFunctions,
    private firestore: AngularFirestore
  ) { }

  // Verificar si es Admin
  public getCurrentUser(uid: string) {
    return this.firestore.collection('usuarios').doc(uid).snapshotChanges()
  }

  public setLocalidad(localidad: string) {
    return this.firestore.collection('usuarios')
      .doc(this.uid)
      .update({
        'localidadActual': localidad
      });
  }

  // get, save Config
  public getConfig(config: string) {
    return this.firestore.doc(`config/${config}`).get().toPromise();
  }
  public async saveConfig(config: string, data: any) {
    return await this.firestore.doc('config/' + config).set(data, { merge: true });
  }
  public getNewId() {
    return this.firestore.createId();
  }
  // update Config
  public updateConfig(config: string, data: {}) {
    return this.firestore.doc(`config/${config}`).update(data);
  }

  // get Prooductos destacados inicio
  public async getMasBuscados(localidad: string) {
    return await this.firestore.collection('productos/localidades/' + localidad).ref.orderBy('masBuscados', 'asc').get();
  }

  public async saveMasBuscados(localidad: string, productosAnteriores: string[], productosDestacados: string[]) {
    // Eliminar los productos que ya no estan en destacados
    for await (const producto of productosAnteriores) {
      try {
        await this.firestore.doc('productos/localidades/' + localidad + '/' + producto).update({
          masBuscados: firebase.default.firestore.FieldValue.delete()
        });
        console.log('Eliminado: ' + producto)
      } catch (e) {
        console.error(e);
      }
    }
    let i = 0;
    productosDestacados = productosDestacados.filter(producto => producto != null);
    for await (const producto of productosDestacados) {
      try {
        await this.firestore.doc('productos/localidades/' + localidad + '/' + producto).update({
          masBuscados: i
        });
        console.log('Agregado: ' + producto, i)
        i++;
      } catch (e) {
        console.error(e);
        throw e;
      }
    }
  }


  // Gets
  public getLocalidades() {
    return this.firestore.collection('localidades_operativas').snapshotChanges();
  }
  public getLocalidadesOperativas() {
    return this.firestore.collection('localidades_operativas').ref.get();
  }
  public getCategorias(localidad: string) {
    return this.firestore.collection(`categorias/localidades/${localidad}`).ref.get();
    //return this.firestore.collection(`categorias`).ref.get();
  }
  public async getCategoriasPromo(localidad: string, comercioId: string) {
    let comercio = await this.firestore.doc('negocios/localidades/' + localidad + '/' + comercioId).get().toPromise();
    return comercio.get('categoriasPromo') ?? [];
  }
  public async getComercios(localidad: string, desactivados: boolean = false) {
    if (desactivados) {
      return this.firestore.collection('negocios/localidades/desactivados').ref.where('localidad', '==', localidad).get();
    }
    return this.firestore.collection('negocios/localidades/' + localidad).ref.get();
  }
  public async getComerciosCadetesExclusivos(localidad: string) {
    return this.firestore.collection('negocios/localidades/' + localidad).ref
      .where('localidad', '==', localidad)
      .where('cadetesExclusivos', '==', true).get();
  }
  public getComercio(localidad: string, id: string) {
    return this.firestore.collection('negocios/localidades/' + localidad).doc(id).get();
  }
  public getProducto(localidad: string, id: string) {
    return this.firestore.collection('productos/localidades/' + localidad).doc(id).get();
  }
  public async getProductos(localidad: string, comercioId: string, desactivados: boolean = false) {
    if (desactivados) {
      return this.firestore.collection('productos/localidades/desactivados').ref.where('negocioId', "==", comercioId).orderBy('nombre').get();
    }
    return this.firestore.collection('productos/localidades/' + localidad).ref
      .where('negocioId', "==", comercioId).orderBy('nombre').get();
  }
  public async getProductosGlobal(localidad: string, desactivados: boolean = false) {
    if (desactivados) {
      return this.firestore.collection('productos/localidades/desactivados').ref.where('localidad', '==', localidad).orderBy('nombre').get();
    }
    return this.firestore.collection('productos/localidades/' + localidad).ref.orderBy('nombre').get();
  }
  public getUsuario(uid: string) {
    return this.firestore.collection('usuarios').doc(uid).get();
  }

  public async getUltimoComprobante(localidad: string, negocioId: string) {
    let compensaciones = await this.firestore
      .collection('negocios/localidades/' + localidad + '/' + negocioId + '/' + 'compensaciones',
        ref => ref.orderBy('fecha', 'desc').limit(1)).get().toPromise();
    let liquidaciones = await this.firestore
      .collection('negocios/localidades/' + localidad + '/' + negocioId + '/' + 'liquidaciones',
        ref => ref.orderBy('fecha', 'desc').limit(1)).get().toPromise();

    const compensacion = compensaciones.docs[0];
    const liquidacion = liquidaciones.docs[0];

    if (compensacion && !liquidacion) return compensacion;
    if (liquidacion && !compensacion) return liquidacion;
    if (compensacion && liquidacion) {
      if (liquidacion.get('fecha').toDate().getTime() > compensacion.get('fecha').toDate().getTime()) {
        return liquidacion;
      } else if (compensacion.get('fecha').toDate().getTime() > liquidacion.get('fecha').toDate().getTime()) {
        return compensacion;
      }
    } else {
      return 0;
    }
  }

  // Updates
  public async des_activarLocalidad(localidad: string, activo: boolean) {
    await this.firestore.doc('localidades_operativas/' + localidad).update({
      activo: activo
    });
  }

  public async updateComercio(localidad: string, id: string, datos: any): Promise<void> {
    await this.firestore.collection('negocios/localidades/' + localidad).doc(id).update(datos)
      .then(
        okay => alert("Comercio con ID " + id + " actualizado con éxito. "),
        rechazado => console.error("Error al actualizar comercio con ID " + id + "\n" + rechazado)
      )
      .then(() => { return; });
  }

  public async modificarCentroDepositos(localidad: string, comercioId: string, valor: boolean) {
    let nuevoCodigo = '';
    for (let i = 0; i < 6; i++) {
      let nuevoDigito: number = Math.floor(Math.random() * 10);
      while (nuevoDigito === 0) {
        nuevoDigito = Math.floor(Math.random() * 10);
      }
      nuevoCodigo += nuevoDigito;
    };
    await this.firestore.doc(`negocios/localidades/${localidad}/${comercioId}/verificacion/actual`).set({
      codigo: Number.parseInt(nuevoCodigo)
    });
    await this.firestore.doc(`negocios/localidades/${localidad}/${comercioId}`)
      .update({
        recibeDepositos: valor,
        depositosInhabilitados: !valor
      });
  }

  public async desactivarComercio(localidad: string, id: string) {
    try {
      const comercio = await this.firestore.doc(`negocios/localidades/${localidad}/${id}`).get().toPromise();
      await comercio.ref.update({
        online: false
      });
      await this.desactivarProductosComercio(localidad, id);
      await this.firestore.doc(`negocios/localidades/desactivados/${id}`).set(comercio.data());
      await comercio.ref.delete();
    } catch (e) {
      throw e;
    }
  }

  public async activarComercio(localidad: string, id: string, activarProductos: boolean) {
    try {
      const comercio = await this.firestore.doc(`negocios/localidades/desactivados/${id}`).get().toPromise();
      if (activarProductos) {
        await this.activarProductosComercio(localidad, id);
      }
      await this.firestore.doc(`negocios/localidades/${localidad}/${id}`).set(comercio.data());
      await comercio.ref.delete();
    } catch (e) {
      throw e;
    }
  }

  private async desactivarProductosComercio(localidad: string, comercioId: string) {
    const productos = await this.firestore.collection(`productos/localidades/${localidad}`)
      .ref.where('negocioId', '==', comercioId)
      .get();

    for await (const producto of productos.docs) {
      await this.firestore.doc(`productos/localidades/desactivados/${producto.id}`).set(producto.data());
      await producto.ref.delete();
    }
  }

  private async activarProductosComercio(localidad: string, comercioId: string) {
    const productos = await this.firestore.collection(`productos/localidades/desactivados`)
      .ref.where('negocioId', '==', comercioId)
      .get();

    for await (const producto of productos.docs) {
      await this.firestore.doc(`productos/localidades/${localidad}/${producto.id}`).set(producto.data());
      await producto.ref.delete();
    }
  }

  public async cerrarComercio(localidad: string, comercioId: string, nuevaDescripcion?: string) {
    try {
      const comercio = await this.firestore.doc(`negocios/localidades/${localidad}/${comercioId}`).get().toPromise();
      const backupDescripcion = comercio.get('descripcion');
      await comercio.ref.update({
        descripcion: nuevaDescripcion ? nuevaDescripcion : backupDescripcion,
        cerrado: true
      });
    } catch (e) {
      throw e;
    }
  }

  public async restaurarHorarios(localidad: string, comercioId: string) {
    try {
      const comercio = await this.firestore.doc(`negocios/localidades/${localidad}/${comercioId}`).get().toPromise();
      const backupDescripcion = comercio.get('backupDescripcion') ?? comercio.get('descripcion') ?? '';
      await comercio.ref.update({
        descripcion: backupDescripcion,
        cerrado: false
      });
    } catch (e) {
      throw e;
    }
  }

  public async getHayComerciosCerrados(localidad: string): Promise<boolean> {
    const comercioCerrado = await this.firestore.collection(`negocios/localidades/${localidad}`, ref => ref.where('cerrado', '==', true).limit(1)).get().toPromise();
    if (comercioCerrado.size > 0) {
      return true;
    }
    return false;
  }

  public async desactivarProducto(localidad: string, productoId: string) {
    const producto = await this.firestore.doc(`productos/localidades/${localidad}/${productoId}`).get().toPromise();
    await this.firestore.doc(`productos/localidades/desactivados/${productoId}`).set(producto.data());
    await producto.ref.delete();
  }

  public async activarProducto(localidad: string, productoId: string) {
    const producto = await this.firestore.doc(`productos/localidades/desactivados/${productoId}`).get().toPromise();
    const comercio = await this.firestore.doc(`negocios/localidades/${localidad}/${producto.get('negocioId')}`).get().toPromise();
    if (comercio.exists) {
      await this.firestore.doc(`productos/localidades/${localidad}/${productoId}`).set(producto.data());
      await producto.ref.delete();
    } else {
      throw new Error("No se puede activar un producto cuyo comercio está desactivado.");
    }
  }

  public updateProducto(localidad: string, id: string, datos: Producto) {
    this.firestore.collection('productos/localidades/' + localidad).doc(id).update(datos)
      .then(
        okay => alert("Producto con ID " + id + " actualizado con éxito. "),
        rechazado => console.error("Error al actualizar producto con ID " + id)
      )
  }

  public async modificarPrecioProducto(localidad: string, pid: string, nuevoPrecio: number) {
    return await this.firestore.doc(`productos/localidades/${localidad}/${pid}`)
      .update({
        precio: nuevoPrecio
      });
  }

  public async guardarProducto(localidad: string, productoId: string, data: {}) {
    return this.firestore.doc(`productos/localidades/${localidad}/${productoId}`)
      .set(data);
  }

  public async guardarPromo(localidad: string, promoId: string, data: {}) {
    return this.firestore.doc(`promociones/localidades/${localidad}/${promoId}`)
      .set(data);
  }

  public async eliminarPromo(localidad: string, promoId: string) {
    return this.firestore.doc(`promos/localidades/${localidad}/${promoId}`).delete()
  }

  // Adds

  public async guardarToken(token: string, localidad: string) {
    return await this.firestore.collection('tokensAdmins')
      .doc('localidades')
      .collection(localidad)
      .doc(token).ref
      .set({
        creado: serverTimestamp(),
        token: token,
        uid: this.uid
      });
  }

  public async guardarLocalidad(localidad: string, data: {}, zonas: any[]) {
    try {
      const localidadRef = this.firestore.doc(`localidades_operativas/${localidad}`);
      await localidadRef.set(data, { merge: true });
      const zonasRef = localidadRef.collection('zonas');
      let i = 0;
      for await (const zona of zonas) {
        i++;
        await zonasRef.doc(i.toString()).set(zona, { merge: true });
      }
    } catch (e) {
      throw e;
    }
  }

  public async getHorariosCadetesAuto(localidad: string) {
    const localidadRef = await this.firestore.collection('localidades_operativas').doc(localidad).get().toPromise();
    return localidadRef.get('horariosCadetesAuto');
  }

  public async addCategoria(localidad: string, nombreCategoria: string, imagenCategoria: string) {
    try {
      await this.firestore.collection('categorias/localidades/' + localidad).doc(nombreCategoria).set({
        nombre: nombreCategoria,
        imagen: imagenCategoria
      });
    } catch (e) {
      throw new Error("Error al crear nueva categoría:\n" + e)
    }
  }

  public async createComercio(id: string, localidad: string, datos: Comercio) {
    try {
      await this.firestore.doc('negocios/localidades/' + localidad + "/" + id).set(datos);
    } catch (e) {
      throw new Error("Error al crear nuevo comercio:\n" + e)

    }
  }
  public createProducto(localidad: string, datos: Producto) {
    this.firestore.collection('productos/localidades/' + localidad).add(datos)
      .then(
        okay => alert("Producto con ID " + okay.id + " creado con éxito. "),
        rechazado => { throw new Error("Error al crear nuevo producto:\n" + rechazado) }
      )
  }

  public async crearAdminComercio(data: {
    legajo: string,
    email: string,
    phoneNumber: string,
    comercioId: string,
    displayName: string,
    password: string,
    localidad_negocio: string,
    localidad: string
  }): Promise<any> {
    try {
      let crearAdminComercio = this.cloudFunctions.httpsCallable('crearAdminComercio');
      return crearAdminComercio(data).toPromise();
    } catch (e) {
      alert(e);
    }
  }

  // Deletes
  public async eliminarCategoria(localidad: string, nombreCategoria: string) {
    try {
      await this.firestore.collection('categorias/localidades/' + localidad).doc(nombreCategoria).delete();
    } catch (e) {
      throw new Error("Error al crear nueva categoría:\n" + e)
    }
  }
  public eliminarComercio(localidad: string, comercioId: string) {
    return this.firestore.collection('negocios/localidades/' + localidad).doc(comercioId).delete()
  }
  public eliminarProducto(localidad: string, productoId: string) {
    return this.firestore.collection('productos/localidades/' + localidad).doc(productoId).delete()
  }

  public async getPedidosCadete(localidad: string, fechaDesde: Date, fechaHasta: Date, cadeteId: string) {
    let pedidosActuales = await this.firestore
      .collection('pedidos_actuales')
      .ref
      .where('localidad', '==', localidad)
      .where('fecha', '>=', fechaDesde)
      .where('fecha', '<=', fechaHasta)
      .where('cadete', '==', cadeteId)
      .get();
    let pedidosElaboradosActuales = await this.firestore
      .collection('pedidos_elaborados_actuales')
      .ref
      .where('localidad', '==', localidad)
      .where('fecha', '>=', fechaDesde)
      .where('fecha', '<=', fechaHasta)
      .where('cadete', '==', cadeteId)
      .get();
    let pedidos = pedidosActuales.docs.concat(pedidosElaboradosActuales.docs);
    return pedidos;
  }

  public async getPedidosFechas(localidad: string, fechaDesde: Date, fechaHasta: Date) {
    let pedidosActuales: QuerySnapshot<unknown> = await this.firestore
      .collection('pedidos_actuales')
      .ref
      .where('localidad', '==', localidad)
      .where('fecha', '>=', fechaDesde)
      .where('fecha', '<=', fechaHasta)
      .where('estado', '==', 'Entregado')
      .get();
    let pedidosElaboradosActuales: QuerySnapshot<unknown> = await this.firestore
      .collection('pedidos_elaborados_actuales')
      .ref
      .where('localidad', '==', localidad)
      .where('fecha', '>=', fechaDesde)
      .where('fecha', '<=', fechaHasta)
      .where('estado', '==', 'Entregado')
      .get();
    let pedidos: QueryDocumentSnapshot<unknown>[] = pedidosActuales.docs.concat(pedidosElaboradosActuales.docs);
    return pedidos;
  }

  public async getPromo(localidad: string, promoId: string) {
    let promo: QueryDocumentSnapshot<unknown> = await this.firestore
      .collection('promociones/localidades/' + localidad)
      .doc(promoId).ref.get();
    return promo;
  }

  public async getPromosLiquidacion(localidad: string, fechaDesde: Date, fechaHasta: Date): Promise<DocumentData[]> {
    let promociones: QuerySnapshot<unknown> = await this.firestore
      .collection('promociones')
      .doc('localidades')
      .collection(localidad)
      .ref
      .get();

    let promosLiquidacion: DocumentData[] = [];

    for await (let promo of promociones.docs) {
      let promoFechaDesde: Date = promo.get('fechaDesde').toDate();
      let promoValidoHasta: Date = promo.get('validoHasta').toDate();
      if (promoFechaDesde.getTime() <= fechaHasta.getTime()
        && promoValidoHasta.getTime() >= fechaDesde.getTime()) {
        promosLiquidacion.push(promo);
      }
    }

    return promosLiquidacion;
  }

  public async getPromociones(localidad: string, fechaDesde: Date, fechaHasta: Date) {
    let promociones: QuerySnapshot<unknown> = await this.firestore
      .collection('promociones')
      .doc('localidades')
      .collection(localidad)
      .ref
      .where('validoHasta', '>', fechaDesde)
      .where('validoHasta', '<=', fechaHasta)
      .get();
    return promociones;
  }
  public async getPromosComercio(localidad: string, fechaDesde: Date, fechaHasta: Date, comercioId: string) {
    let promos: QuerySnapshot<unknown> = await this.firestore
      .collection('promociones')
      .doc('localidades')
      .collection(localidad)
      .ref
      .where('validoHasta', '>', fechaDesde)
      .where('validoHasta', '<=', fechaHasta)
      .get();
    let promosComercio: QueryDocumentSnapshot<unknown>[] = [];
    promos.docs.forEach(promo => {
      if (promo.get('comercios').includes(comercioId)) {
        promosComercio.push(promo);
      }
    });
    return promosComercio;
  }
  public async getPromosActivas(localidad: string) {
    let promos: QuerySnapshot<unknown> = await this.firestore
      .collection('promociones')
      .doc('localidades')
      .collection(localidad)
      .ref
      .where('activo', '==', true).get();
    return promos.docs;
  }

  public async getDepositosTodos(localidad: string, solo_verificados: boolean, fechaDesde: Date, fechaHasta: Date = new Date()) {
    let depositosTodos = [];
    if (!solo_verificados) {
      const centrosDepositos = await this.firestore.collection('negocios/localidades/' + localidad).ref.where('recibeDepositos', '==', true).get();
      for await (const centro of centrosDepositos.docs) {
        const depositos = await centro.ref.collection('depositos').where('fecha', '>=', fechaDesde).where('fecha', '<=', fechaHasta).orderBy('fecha').get();
        depositosTodos = depositosTodos.concat(depositos.docs);
      }
    } else {
      const centrosDepositos = await this.firestore.collection('negocios/localidades/' + localidad).ref.where('recibeDepositos', '==', true).get();
      for await (const centro of centrosDepositos.docs) {
        const depositos = await centro.ref.collection('depositos').where('fecha', '>=', fechaDesde).where('fecha', '<=', fechaHasta).orderBy('fecha').get();
        depositosTodos = depositosTodos.concat(depositos.docs.filter(deposito => deposito.get('verificado')));
      }
    }
    return depositosTodos;
  }

  public async getDepositos(fechaDesde: Date, fechaHasta: Date, localidad: string, negocioId: string) {
    let depositos: QuerySnapshot<unknown> = await this.firestore
      .collection(`/negocios/localidades/${localidad}/${negocioId}/depositos`)
      .ref
      .where('fecha', '>=', fechaDesde)
      .where('fecha', '<=', fechaHasta)
      .where('verificado', '==', true)
      .get();
    let _depositos = depositos.docs;
    return _depositos;
  }
  public async getDepositosPendientes(fechaDesde: Date, fechaHasta: Date, localidad: string, negocioId: string) {
    let depositos: QuerySnapshot<unknown> = await this.firestore
      .collection(`/negocios/localidades/${localidad}/${negocioId}/depositos/`)
      .ref
      .where('fecha', '>=', fechaDesde)
      .where('fecha', '<=', fechaHasta)
      .where('verificado', '==', false)
      .get();
    let _depositos = depositos.docs;
    return _depositos;
  }

  public async getCadetes(localidad: string, activos: boolean = true): Promise<QuerySnapshot<unknown>> {
    let cadetes: QuerySnapshot<unknown>;
    if (localidad) {
      cadetes = await this.firestore.collection('usuarios')
        .ref.where('cadete', '==', true)
        .where('activo', '==', activos)
        .where('localidad', '==', localidad).get();
    } else {
      cadetes = await this.firestore.collection('usuarios')
        .ref.where('cadete', '==', true).get();
    }
    return cadetes;
  }
  public async getCadete(uid: string) {
    let cadete = await this.firestore.doc('usuarios/' + uid).ref.get();

    if (cadete.exists) {
      return cadete;
    } else {
      throw new Error('Cadete no existe.');
    }
  }

  public async getVehiculos() {
    const vehiculos = await this.firestore.collection('config').doc('limites_carrito').get().toPromise();
    return vehiculos.get('vehiculos') ?? [];
  }

  public async guardarVehiculos(vehiculos: any[]) {
    return await this.firestore.collection('config').doc('limites_carrito').update({ vehiculos: vehiculos });
  }

  public async getTurnos(localidad: string, fechas: Date[]): Promise<any> {
    const semana: any = {};
    for await (const fecha of fechas) {
      const turnos = await this.firestore
        .collection(`turnos/localidades/${localidad}/${fecha.getFullYear().toString()}/${(fecha.getMonth() + 1).toString()}/${fecha.getDate().toString()}/turnos`)
        .ref.orderBy('inicio', 'asc').get();
      semana[fecha.getDate()] = turnos.docs;
    }
    return semana;
  }

  public async crearTurno(localidad: string, turno: any) {
    const turnoRef = await this.firestore.doc(
      'turnos/localidades/' + localidad + '/' +
      turno.inicio.getFullYear().toString() + '/' +
      (turno.inicio.getMonth() + 1).toString() + '/' +
      turno.inicio.getDate().toString() + '/turnos/' +
      turno.inicio.getHours() + ':' + turno.inicio.getMinutes() + '-' +
      new Date(turno.inicio.getTime() + (turno.duracion * 60 * 1000)).getHours() + ':' +
      new Date(turno.inicio.getTime() + (turno.duracion * 60 * 1000)).getMinutes()
    ).get().toPromise();
    if (turnoRef.exists) {
      alert('Este turno ya existe.');
      return;
    }
    return await turnoRef.ref.set(turno);
  }

  public async agregarCadetesTurno(localidad: string, turno: QueryDocumentSnapshot<unknown>, cadetes: string[]) {
    try {
      const confirmadosActuales = turno.get('confirmados') ?? [];
      const confirmados = confirmadosActuales.concat(cadetes);
      await turno.ref.update({
        confirmados: confirmados
      });
    } catch (e) {
      throw e;
    }
  }

  public async quitarCadeteTurno(turno: QueryDocumentSnapshot<unknown>, cadete: string) {
    try {
      const confirmadosActuales = turno.get('confirmados') ?? [];
      const confirmados = confirmadosActuales.filter(c => c != cadete);
      await turno.ref.update({
        confirmados: confirmados
      });
    } catch (e) {
      throw e;
    }
  }

  public async getAdminsPanelControl(localidad: string) {
    const admins: QuerySnapshot<unknown> = await this.firestore.collection('usuarios')
      .ref.where('admin', '==', true)
      .where('localidadActual', '==', localidad).get();
    return admins.docs;
  }

  public async getAdminsComercios(localidad: string | null): Promise<QuerySnapshot<unknown>> {
    let admins: QuerySnapshot<unknown>;
    if (localidad) {
      admins = await this.firestore.collection('usuarios')
        .ref.where('negocio', '!=', null)
        .where('localidad', '==', localidad).get();
    } else {
      admins = await this.firestore.collection('usuarios')
        .ref.where('negocio', '!=', null).get();
    }
    return admins;
  }

  public async cantidadPedidos(localidad: string, desde: Date, hasta: Date) {

    let pedidos: QuerySnapshot<unknown> = await this.firestore
      .collection('pedidos_actuales')
      .ref
      .where('localidad', '==', localidad)
      .where('fecha', '>=', desde)
      .where('fecha', '<=', hasta)
      .where('estado', '==', 'Entregado')
      .get();

    let pedidosElaborados: QuerySnapshot<unknown> = await this.firestore
      .collection('pedidos_elaborados_actuales')
      .ref
      .where('localidad', '==', localidad)
      .where('fecha', '>=', desde)
      .where('fecha', '<=', hasta)
      .where('estado', '==', 'Entregado')
      .get();

    let cantidad: number = pedidos.docs.length + pedidosElaborados.docs.length;
    return cantidad;
  }

  public async getPedidos(localidad: string, desde: Date, hasta: Date) {
    let pedidosActuales: QuerySnapshot<unknown> = await this.firestore
      .collection('pedidos_actuales')
      .ref
      .where('localidad', '==', localidad)
      .where('fecha', '>=', desde)
      .where('fecha', '<=', hasta)
      .where('estado', '==', 'Entregado')
      .get();

    let pedidosElaboradosActuales: QuerySnapshot<unknown> = await this.firestore
      .collection('pedidos_elaborados_actuales')
      .ref
      .where('localidad', '==', localidad)
      .where('fecha', '>=', desde)
      .where('fecha', '<=', hasta)
      .where('estado', '==', 'Entregado')
      .get();

    let pedidos: QueryDocumentSnapshot<unknown>[] = pedidosActuales.docs;
    pedidos = pedidos.concat(pedidosElaboradosActuales.docs);
    pedidos.sort((a, b) => {
      let _a = a.data();
      let _b = b.data();
      return _a['fecha'] - _b['fecha'];
    });
    return pedidos;
  }

  public async modificarPedido(pedido: DocumentSnapshot<unknown>, productos, sinStock, precioPedido: number, precioEnvio: number, modificacion: any) {
    const modificaciones = pedido.get('modificaciones') ?? [];
    modificaciones.push(modificacion);
    pedido.ref.update({
      productos: productos ?? [],
      sinStock: sinStock ?? [],
      precioPedido: precioPedido,
      precioDelivery: precioEnvio,
      modificaciones: modificaciones
    });
  }

  public async getPedidosUsuario(uid: string) {
    let pedidosActuales: QuerySnapshot<unknown> = await this.firestore
      .collection('pedidos_actuales')
      .ref
      .where('uid', '==', uid)
      .get();

    let pedidosElaboradosActuales: QuerySnapshot<unknown> = await this.firestore
      .collection('pedidos_elaborados_actuales')
      .ref
      .where('uid', '==', uid)
      .get();

    let pedidos: QueryDocumentSnapshot<unknown>[] = pedidosActuales.docs;
    pedidos = pedidos.concat(pedidosElaboradosActuales.docs);
    pedidos.sort((a, b) => {
      let _a = a.data();
      let _b = b.data();
      return _a['fecha'] - _b['fecha'];
    });
    return pedidos;
  }

  public async getPedidosEnCurso(localidad: string, desde: Date, hasta: Date) {
    let pedidos: QuerySnapshot<unknown> = await this.firestore
      .collection('pedidos_actuales')
      .ref
      .where('localidad', '==', localidad)
      .where('fecha', '>=', desde)
      .where('fecha', '<=', hasta)
      .where('estado', 'in', ['Aceptado', 'Preparando', 'Listo', 'Yendo'])
      .get();
    let pedidosEsperando: QuerySnapshot<unknown> = await this.firestore
      .collection('pedidos_actuales')
      .ref
      .where('localidad', '==', localidad)
      .where('fecha', '>=', desde)
      .where('fecha', '<=', hasta)
      .where('estado', '==', null)
      .get();

    let pedidosElaborados: QuerySnapshot<unknown> = await this.firestore
      .collection('pedidos_elaborados_actuales')
      .ref
      .where('localidad', '==', localidad)
      .where('fecha', '>=', desde)
      .where('fecha', '<=', hasta)
      .where('estado', 'in', ['Aceptado', 'Preparando', 'Listo', 'Yendo'])
      .get();
    let pedidosElaboradosEsperando: QuerySnapshot<unknown> = await this.firestore
      .collection('pedidos_elaborados_actuales')
      .ref
      .where('localidad', '==', localidad)
      .where('fecha', '>=', desde)
      .where('fecha', '<=', hasta)
      .where('estado', '==', null)
      .get();

    let pedidosEnCurso: QueryDocumentSnapshot<unknown>[] = pedidos.docs;
    pedidosEnCurso = pedidosEnCurso.concat(pedidosEsperando.docs, pedidosElaborados.docs, pedidosElaboradosEsperando.docs);
    pedidosEnCurso.sort((a, b) => a.get('fecha') - b.get('fecha'));
    return pedidosEnCurso;
  }

  public async getPedidosTodos(localidad: string, desde: Date, hasta: Date) {
    let pedidosActuales: QuerySnapshot<unknown> = await this.firestore
      .collection('pedidos_actuales')
      .ref
      .where('localidad', '==', localidad)
      .where('fecha', '>=', desde)
      .where('fecha', '<=', hasta)
      .get();

    let pedidosElaboradosActuales: QuerySnapshot<unknown> = await this.firestore
      .collection('pedidos_elaborados_actuales')
      .ref
      .where('localidad', '==', localidad)
      .where('fecha', '>=', desde)
      .where('fecha', '<=', hasta)
      .get();

    let pedidos: QueryDocumentSnapshot<unknown>[] = pedidosActuales.docs;
    pedidos = pedidos.concat(pedidosElaboradosActuales.docs);
    pedidos.sort((a, b) => {
      let _a = a.data();
      let _b = b.data();
      return _a['fecha'] - _b['fecha'];
    });

    return pedidos;
  }

  public async getPedidosParaAuto(localidad: string, desde: Date) {
    let pedidos: QuerySnapshot<unknown> = await this.firestore
      .collection('pedidos_actuales')
      .ref
      .where('localidad', '==', localidad)
      .where('horarioEntrega', '>=', desde)
      .get();

    let pedidosElaborados: QuerySnapshot<unknown> = await this.firestore
      .collection('pedidos_elaborados_actuales')
      .ref
      .where('localidad', '==', localidad)
      .where('horarioEntrega', '>=', desde)
      .get();

    let pedidosAuto: QueryDocumentSnapshot<unknown>[] = pedidos.docs;
    pedidosAuto = pedidosAuto.concat(pedidosElaborados.docs);
    pedidosAuto.sort((a, b) => a.get('horarioEntrega') - b.get('horarioEntrega'));
    return pedidosAuto;
  }

  public async getUltimaJornadaCadete(cid: string) {
    const jornada = await this.firestore.collection(`cuentas_corrientes/${cid}/jornadas`)
      .ref
      .orderBy('inicio', 'asc')
      .limitToLast(1)
      .get();
    return jornada.docs[0];
  }

  public async getJornadasCadete(cadeteId: string, fecha: Date) {
    fecha.setHours(3, 0, 0, 0);
    const jornadas = await this.firestore.collection(`cuentas_corrientes/${cadeteId}/jornadas`)
      .ref
      .where('inicio', '>=', fecha)
      .get();
    return jornadas.docs;
  }

  public async getIngresosJornada(localidad: string, desde: Date, hasta: Date): Promise<number> {
    let pedidos = await this.getPedidos(localidad, desde, hasta);
    let cantidadPedidos: number = pedidos.length;
    let i: number = 0;
    let ingresos: number = 0;
    return new Promise(
      resolve => {
        if (pedidos.length != 0) {
          pedidos.forEach(pedido => {
            let _pedido = pedido.data();
            ingresos += _pedido['precioPedido'];
            i++;
            if (i == cantidadPedidos) {
              resolve(ingresos);
            }
          });
        } else {
          resolve(0);
        }
      }
    );
  }

  public async getIngresosViajes(localidad: string, desde: Date, hasta: Date): Promise<number> {
    let pedidos = await this.getPedidos(localidad, desde, hasta);
    let cantidadPedidos: number = pedidos.length;
    let i: number = 0;
    let ingresos: number = 0;
    return new Promise(
      resolve => {
        if (pedidos.length != 0) {
          pedidos.forEach(pedido => {
            let _pedido = pedido.data();
            ingresos += _pedido['precioDelivery'];
            i++;
            if (i == cantidadPedidos) {
              resolve(ingresos);
            }
          });
        } else {
          resolve(0);
        }
      }
    );
  }

  public async getUsuarios(localidad: string, desde: Date, hasta: Date) {
    let usuarios = await this.firestore.collection('usuarios')
      .ref
      .where('localidad', '==', localidad)
      .where('creado', '>=', desde)
      .where('creado', '<=', hasta)
      .get();

    if (usuarios.docs.length != 0) {
      return usuarios.docs;
    } else {
      return [];
    }
  }

  public async getCantidadUsuariosRegistrados(localidad: string, desde: Date, hasta: Date) {
    let usuarios = await this.firestore.collection('usuarios')
      .ref
      .where('localidad', '==', localidad)
      .where('creado', '>=', desde)
      .where('creado', '<=', hasta)
      .get();

    return usuarios.size;
  }

  public async getUsuariosLocalidad(localidad: string) {
    return await this.firestore.collection('usuarios').ref
      .where('localidad', '==', localidad)
      .get();
  }

  public async getTodosLosUsuarios() {
    return await this.firestore.collection('usuarios').ref
      .where('cadete', '==', null)
      .get();
  }

  public async updateUsuario(uid: string, data: any) {
    await this.firestore.collection('usuarios').doc(uid).update(data);
  }

  public async getTokenUsuario(uid: string): Promise<string[]> {
    const tokens = await this.firestore.collection('usuarios').doc(uid).collection('tokens').ref.orderBy('creado', 'desc').get();
    return tokens.docs.map(doc => doc.get('token'));
  }

  // LIQUIDACION

  public async getLiquidacion(localidad: string, comercioId: string, liquidacionId: string) {
    return this.firestore
      .collection('negocios')
      .doc('localidades')
      .collection(localidad)
      .doc(comercioId)
      .collection('liquidaciones')
      .doc(liquidacionId)
      .get();
  }

  public async getLiquidaciones(localidad: string, comercioId: string) {
    let liquidaciones = this.firestore
      .collection('negocios')
      .doc('localidades')
      .collection(localidad)
      .doc(comercioId)
      .collection('liquidaciones')
      .ref.orderBy('fecha', 'asc')
      .get();
    return liquidaciones;
  }
  public async getLiquidacionesFechas(localidad: string, comercioId: string, fechaDesde: Date, fechaHasta: Date) {
    let liquidaciones = this.firestore
      .collection('negocios')
      .doc('localidades')
      .collection(localidad)
      .doc(comercioId)
      .collection('liquidaciones')
      .ref
      .where('vencimiento', '>=', fechaDesde)
      .where('vencimiento', '<=', fechaHasta)
      .orderBy('vencimiento', 'asc')
      .get();
    return liquidaciones;
  }

  public async getLiquidacionesGeneral(localidad: string, desde: Date, hasta: Date) {
    let liquidaciones = [];
    const comercios = await this.firestore.collection('negocios/localidades/' + localidad).get().toPromise();
    for await (const comercio of comercios.docs) {
      const liquidacion = await comercio.ref.collection('liquidaciones')
        .where('fecha', '>', desde)
        .where('fecha', '<=', hasta)
        .orderBy('fecha', 'asc').get();
      if (liquidacion.docs.length > 0) {
        if (liquidacion.docs[0].get('tipo') != 'Liquidacion') {
          if (liquidacion.docs[1].get('tipo') != 'Liquidacion') {
            liquidaciones.push(liquidacion.docs[2]);
          } else {
            liquidaciones.push(liquidacion.docs[1]);
          }
        } else {
          liquidaciones.push(liquidacion.docs[0]);
        }
      }
    }
    let _liquidaciones = liquidaciones.filter(doc => doc.get('tipo') == 'Liquidacion');
    return _liquidaciones;
  }

  public async guardarLiquidacion(
    localidad: string,
    comercioId: string,
    numero: number,
    pedidos: object,
    compensaciones: object,
    depositos: object,
    promociones: object
  ): Promise<void> {
    let data = {};
    data = {
      'fecha': new Date(),
      'numero': numero,
      'pedidos': pedidos,
      'depositos': depositos,
      'compensaciones': compensaciones,
      'promociones': promociones,
    };
    await this.firestore.collection('negocios/localidades/' + localidad + "/" + comercioId + "/liquidaciones")
      .add(data);
  }

  // CUENTAS CORRIENTES

  public async getCompensaciones(localidad: string, comercioId: string) {
    // Calcular fecha ultima liquidacion
    let ahora = new Date();
    let ultimaLiquidacionAntes = new Date(ahora.getFullYear(), ahora.getMonth() - 1, 16, 5, 0, 0, 0);
    let ultimaLiquidacion1 = new Date(ahora.getFullYear(), ahora.getMonth(), 1, 5, 0, 0, 0);
    let ultimaLiquidacion16 = new Date(ahora.getFullYear(), ahora.getMonth(), 16, 5, 0, 0, 0);
    let fechaDesde: Date;
    if (ahora.getTime() < ultimaLiquidacion1.getTime()) fechaDesde = new Date(ultimaLiquidacionAntes);
    if (ahora.getTime() > ultimaLiquidacion1.getTime()) fechaDesde = new Date(ultimaLiquidacion1);
    if (ahora.getTime() > ultimaLiquidacion16.getTime()) fechaDesde = new Date(ultimaLiquidacion16);

    let compensaciones = this.firestore
      .collection('negocios/localidades/' + localidad + '/' + comercioId + '/compensaciones')
      .ref
      .where('fecha', '>', fechaDesde)
      .orderBy('fecha', 'asc')
      .get();
    return compensaciones;
  }

  public async getCompensacionesLiquidacion(localidad: string, comercioId: string, fechaDesde: Date, fechaHasta: Date) {
    let compensaciones = this.firestore
      .collection('negocios/localidades/' + localidad + '/' + comercioId + '/compensaciones')
      .ref
      .where('fecha', '>=', fechaDesde)
      .where('fecha', '<', fechaHasta)
      .orderBy('fecha', 'asc')
      .get();
    return compensaciones;
  }

  public async getCompensacionesGeneral(localidad: string, desde: Date, hasta: Date) {
    let compensaciones = [];
    const comerciosActivados = await this.firestore.collection('negocios/localidades/' + localidad)
      .get().toPromise();
    for await (const comercio of comerciosActivados.docs) {
      const compensacion = await comercio.ref.collection('/compensaciones')
        .where('fecha', '>', desde)
        .where('fecha', '<=', hasta).get();
      if (compensacion.docs.length > 0) {
        if (compensacion.docs[0].get('tipo') != 'compensacion') {
          if (compensacion.docs[1].get('tipo') != 'compensacion') {
            compensaciones.push(compensacion.docs[2]);
          } else {
            compensaciones.push(compensacion.docs[1]);
          }
        } else {
          compensaciones.push(compensacion.docs[0]);
        }
      }
    }
    compensaciones = compensaciones.filter(doc => doc.get('tipo') == 'compensacion');
    compensaciones.sort((a, b) => {
      return a.get('fecha').toDate().getTime() - b.get('fecha').toDate().getTime();
    });
    return compensaciones;
  }

  public async getCompensacionesSinCancelar() {
    const semana: Date = new Date();
    semana.setDate(semana.getDate() - 7);
    let compensaciones = await this.firestore
      .collectionGroup('compensaciones', doc => doc
        .orderBy('fecha', 'asc')).get().toPromise();
    let _compensaciones = compensaciones.docs;
    _compensaciones = _compensaciones.filter(compensacion => compensacion.get('tipo') == 'compensacion');
    _compensaciones = _compensaciones.filter(compensacion => !compensacion.get('cancelado'));
    _compensaciones = _compensaciones.filter(compensacion => compensacion.get('total') != 0);
    return _compensaciones;
  }

  public async getLiquidacionesSinCancelar() {
    let liquidaciones = await this.firestore.collectionGroup('liquidaciones').get().toPromise();
    let _liquidaciones = liquidaciones.docs.filter(doc => !doc.get('cancelado'));
    _liquidaciones.sort((a, b) => a.get('fecha').toDate().getTime() - b.get('fecha').toDate().getTime());
    return _liquidaciones;
  }

  public async getSaldosCuentasCorrientes(localidad: string, detalles: boolean = false) {
    const getSaldosComercios = this.cloudFunctions.httpsCallable('getSaldosComercios');
    return await getSaldosComercios({ localidad: localidad, detalles: detalles }).toPromise();
  }

  public async nuevoMovimiento(
    destino: string,
    localidad: string,
    comercioId: string,
    numero: number,
    tipo: string,
    fecha: Date,
    observacion: string,
    total: number
  ) {
    let path = 'negocios/localidades/' + localidad + '/' + comercioId + '/' + destino;
    return await this.firestore.collection(path)
      .add({
        'numero': numero,
        'tipo': tipo,
        'fecha': fecha,
        'observacion': observacion,
        'total': total
      });
  }

  async getReciboCompensaciones(localidad: string, comercioId: string, reciboId: string) {
    return await this.firestore.doc(`/negocios/localidades/${localidad}/${comercioId}/compensaciones/${reciboId}`).get().toPromise();
  }

  async getReciboLiquidaciones(localidad: string, comercioId: string, reciboId: string) {
    return await this.firestore.doc(`/negocios/localidades/${localidad}/${comercioId}/cuenta-corriente/${reciboId}`).get().toPromise();
  }

  async crearComprobanteCompensaciones(localidad: string, compensaciones: QueryDocumentSnapshot<unknown>[], monto: number, compensacionParcial: QueryDocumentSnapshot<unknown>, montoParcial: number, numero: string, tipo: "Recibo" | "Orden de pago") {
    try {
      let _compensaciones;
      if (!compensacionParcial) {
        _compensaciones = compensaciones.map(compensacion => compensacion.ref);
      } else {
        _compensaciones = compensaciones.map(compensacion => compensacion.ref).concat(compensacionParcial.ref);
      }
      const nuevoRecibo = await this.firestore.collection(`negocios/localidades/${localidad}/${compensaciones[0].ref.parent.parent.id}/compensaciones`)
        .add({
          numero: numero,
          tipo: tipo,
          fecha: serverTimestamp(),
          total: monto,
          compensaciones: _compensaciones,
        });
      for await (const compensacion of compensaciones) {
        const total = compensacion.get('total');
        await compensacion.ref
          .update({
            recibo: nuevoRecibo.id,
            cancelado: true,
            saldoCancelado: total
          });
      }
      const saldoCancelado = compensacionParcial?.get('saldoCancelado');
      await compensacionParcial?.ref
        .update({
          recibo: nuevoRecibo.id,
          saldoCancelado: saldoCancelado + montoParcial,
        });
      const numeros_comprobantes_ref = this.firestore.collection('config').doc('numeros_comprobantes').ref;
      if (tipo == "Recibo") {
        await numeros_comprobantes_ref.update('recibo', increment(1));
      } else if (tipo == "Orden de pago") {
        await numeros_comprobantes_ref.update('orden_de_pago', increment(1));
      }
    } catch (e) {
      alert("Error, ver consola\n" + e.message);
    }

  }

  async crearComprobanteLiquidaciones(localidad: string, liquidaciones: QueryDocumentSnapshot<unknown>[], monto: number, liquidacionParcial: QueryDocumentSnapshot<unknown>, montoParcial: number, numero: string, tipo: "Recibo" | "Orden de pago") {
    try {
      let _liquidaciones;
      if (!liquidacionParcial) {
        _liquidaciones = liquidaciones.map(compensacion => compensacion.ref);
      } else {
        _liquidaciones = liquidaciones.map(compensacion => compensacion.ref).concat(liquidacionParcial.ref);
      }
      const nuevoComprobante = await this.firestore.collection(`negocios/localidades/${localidad}/${liquidaciones[0].ref.parent.parent.id}/liquidaciones`)
        .add({
          numero: numero,
          tipo: tipo,
          fecha: serverTimestamp(),
          total: monto,
          liquidaciones: _liquidaciones,
        });
      for await (const liquidacion of liquidaciones) {
        const total = liquidacion.get('total');
        await liquidacion.ref
          .update({
            recibo: nuevoComprobante.id,
            cancelado: true,
            saldoCancelado: total
          });
      }
      const saldoCancelado = liquidacionParcial?.get('saldoCancelado');
      await liquidacionParcial?.ref
        .update({
          recibo: nuevoComprobante.id,
          saldoCancelado: saldoCancelado + montoParcial,
        });
      const numeros_comprobantes_ref = this.firestore.collection('config').doc('numeros_comprobantes').ref;
      if (tipo == "Recibo") {
        await numeros_comprobantes_ref.update('recibo', increment(1));
      } else if (tipo == "Orden de pago") {
        await numeros_comprobantes_ref.update('orden_de_pago', increment(1));
      }
    } catch (e) {
      alert("Error, ver consola\n" + e.message);
    }

  }

  public async eliminarItemCuentaCorriente(localidad: string, comercioId: string, id: string) {
    await this.firestore.collection('negocios/localidades/' + localidad + '/' + comercioId + '/liquidaciones')
      .doc(id)
      .delete();
  }

  public async eliminarDeCompensaciones(localidad: string, comercioId: string, id: string) {
    await this.firestore.collection('negocios/localidades/' + localidad + '/' + comercioId + '/compensaciones')
      .doc(id)
      .delete();
  }

  //Obtener legajo nuevo admin comercio
  public async nuevoLegajoAdminComercio(localidad: string): Promise<string> {
    let admins = await this.getAdminsComercios(localidad);
    let cantidadAdmins = admins.docs.length;
    let legajo: string = localidad.substring(0, 4) + "000".substring(0, 3 - (cantidadAdmins.toString().length)) + cantidadAdmins.toString();
    return legajo;
  }

  //Obtener legajo nuevo cadete
  public async nuevoLegajoCadete(localidad: string): Promise<string> {
    let cadetesRef = this.firestore.collection('usuarios').ref.where('cadete', '==', true).where('localidad', '==', localidad).get();
    const cantidadCadetes = (await cadetesRef).size + 1;
    let legajo: string = localidad.substring(0, 4) + "000".substring(0, 3 - (cantidadCadetes.toString().length)) + cantidadCadetes.toString();
    return legajo;
  }

  //Obtener comprobantes de un cadete
  public async getComprobantesCadete(uid: string): Promise<any[]> {
    let saldo: number = 0;
    let _comprobantes = [];
    let comprobantes = await this.firestore
      .collection('usuarios/' + uid + '/facturacion')
      .ref.orderBy('fecha', 'asc')
      .get();
    let i: number = 0;
    comprobantes.docs.forEach(async comprobante => {
      let _comprobante = comprobante.data();
      let emisor = await this.firestore.doc('usuarios/' + comprobante.get('emisor')).get().toPromise();
      let nombreEmisor = emisor.get('nombre');
      _comprobante['emisorNombre'] = nombreEmisor;
      _comprobante['id'] = comprobante.id;
      saldo += comprobante.get('totalComprobante');
      _comprobante['saldo'] = saldo;
      _comprobantes.push(_comprobante);
      i++;
      if (i == comprobantes.docs.length) {
        return;
      }
    });
    return _comprobantes;
  }

  //Guardar factura cadete
  public async addComprobanteCadete(data, cadeteId: string, localidad: string) {
    let facturacionCollection = this.firestore
      .collection('usuarios/' + cadeteId + '/facturacion')
      .ref;
    await facturacionCollection.add(data);
  }

  //Obtener liquidaciones de un cadete
  public async getLiquidacionesCadete(cadeteId: string, localidad: string, fechaDesde: Date, fechaHasta: Date): Promise<any[]> {
    let liquidaciones = <any>[];
    let _liquidaciones = await this.firestore
      .collection('usuarios/' + cadeteId + '/liquidaciones')
      .ref
      .orderBy('fechaPeriodoHasta')
      .get();
    return new Promise<any[]>(resolve => {
      if (_liquidaciones.docs.length > 0) {
        _liquidaciones.docs.forEach((liquidacion, i) => {
          let _fechaHasta: Date = liquidacion.get('fechaPeriodoHasta').toDate();
          if (fechaDesde.valueOf() < _fechaHasta.valueOf()
            && fechaHasta.valueOf() > _fechaHasta.valueOf()) {
            let _liquidacion = liquidacion.data();
            _liquidacion['id'] = liquidacion.id;
            liquidaciones.push(_liquidacion);
          }
          if (i == _liquidaciones.docs.length - 1) {
            resolve(liquidaciones);
          }
        });
      } else {
        resolve([]);
      }
    });
  }

  // Obtener ultima liquidacion del cadete
  public async getUltimaLiquidacionCadete(cadeteId: string, localidad: string) {
    let ultimaLiquidacion = await this.firestore
      .collection('usuarios/' + cadeteId + '/liquidaciones')
      .ref
      .orderBy('fecha')
      .limitToLast(1)
      .get();
    return ultimaLiquidacion.docs[0];
  }

  // Obtener los KMs recorridos de un pedido
  public async getKMRecorridos(localidad: string, cadeteId: string, fechaDesde: Date, fechaHasta: Date): Promise<number> {
    let kms: number = 0;
    return new Promise<number>(async resolve => {
      let pedidos = await this.getPedidosCadete(localidad, fechaDesde, fechaHasta, cadeteId);
      if (pedidos.length > 0) {
        pedidos.forEach((pedido, i) => {
          kms += pedido.get('kmRecorridos')
          if (i == pedidos.length - 1) {
            resolve(kms);
          }
        });
      } else {
        resolve(0);
      }

    });

  }

  // Actualizar precios de productos en lote
  public async updatePreciosProductosEnLote(localidad: string, productos: any[]) {
    try {
      let updatePreciosProductos = this.cloudFunctions.httpsCallable('updatePreciosProductos');
      let data = {
        localidad: localidad,
        productos: productos
      };
      let respuesta = await updatePreciosProductos(data).toPromise();
      alert(respuesta['result']);
    } catch (e) {
      alert(e);
      throw new Error(e.message);
    }
  }

  // Recuperar cuenta
  public async reestablecerPass(uid: string, newPass: string) {
    try {
      let cloudFunction = this.cloudFunctions.httpsCallable('recuperarCuenta');
      let data = {
        uid: uid,
        newPass: newPass
      };
      cloudFunction(data).toPromise().then(respuesta => {
        if (respuesta.ok) {
          this.firestore.doc('usuarios/' + uid).update({ passCambiada: false }).then(() => {
            alert("Se ha reestablecido la cuenta");
          });
        }
      });
    } catch (e) {
      alert(e);
    }
  }

  // Des/bloquear cuenta
  public async des_bloquearCuenta(uid: string, bloquear: boolean) {
    try {
      let cloudFunction = this.cloudFunctions.httpsCallable('des_bloquearCuenta');
      let data = {
        uid: uid,
        bloquear: bloquear
      };
      await this.firestore.doc('usuarios/' + uid).update({ bloqueado: bloquear });
      return cloudFunction(data).toPromise();
    } catch (e) {
      alert(e);
    }
  }

  // get cupones
  async getCupones(localidad: string) {
    return this.firestore.collection(`cupones/localidades/${localidad}`).get();
  }

  async guardarCupon(localidad: string, data: any) {
    return await this.firestore.collection(`cupones/localidades/${localidad}`).add(data);
  }

  async modificarCupon(localidad: string, id: string, data: any) {
    return await this.firestore.doc(`cupones/localidades/${localidad}/${id}`).update(data);
  }


  // BANNERS
  getBanners(localidad: string) {
    return this.firestore.collection('publicidad/localidades/' + localidad).snapshotChanges();
  }

  async guardarBanner(localidad: string, numero: string, data: any, nuevo: boolean) {
    if (nuevo) {
      return await this.firestore.collection('publicidad/localidades/' + localidad).doc(numero).set(data);
    } else {
      return await this.firestore.collection('publicidad/localidades/' + localidad).doc(numero).update(data);
    }
  }

  async eliminarBanner(localidad: string, numero: string) {
    const banner = await this.firestore.collection('publicidad/localidades/' + localidad).doc(numero).ref.get();
    if (banner.get('activo')) {
      throw Error('No se puede eliminar un banner activo');
    }
    return await this.firestore.collection('publicidad/localidades/' + localidad).doc(numero).delete();
  }

  async moverBanner(localidad: string, bannerId: string, destino: string) {
    const id = Number.parseInt(bannerId);
    const banner = await this.firestore.collection('publicidad/localidades/' + localidad).doc(bannerId).ref.get();
    const bannerDestino = await this.firestore.collection('publicidad/localidades/' + localidad).doc(destino == "subir" ? (id - 1).toString() : (id + 1).toString()).ref.get();
    const bannerData = banner.data();
    const bannerDestinoData = bannerDestino.data();
    console.log(id);
    await banner.ref.set(bannerDestinoData);
    await bannerDestino.ref.set(bannerData);
    return;
  }

  crearBatch() {
    return this.firestore.firestore.batch();
  }

  nuevoDocumentRef(collectionPath: string, docId: string = null) {
    if (!docId) {
      return this.firestore.firestore.collection(collectionPath).doc();
    } else {
      return this.firestore.firestore.collection(collectionPath).doc(docId);
    }
  }

  async getEstadosPreviosACompensaciones(localidad: string) {
    return (await this.firestore.collection('localidades_operativas/' + localidad + '/' + 'estado_actual')
      .ref.orderBy('fecha', 'desc').limit(10).get()).docs;
  }

  async getCadetesExclusivos(comercioId: string) {
    return (await this.firestore.collection('usuarios').ref.where('comercioExclusivo', '==', comercioId).get()).docs;
  }

  async getModoLluvia(localidad: string) {
    const localidadOperativa = await this.firestore.doc("localidades_operativas/" + localidad).get().toPromise();
    return localidadOperativa.get('modoLluvia');
  }

  async activarModoLluvia(localidad: string, valor: boolean) {
    try {
      const localidadOperativa = await this.firestore.doc('localidades_operativas/' + localidad).get().toPromise();
      await localidadOperativa.ref.update({ modoLluvia: valor });
      await localidadOperativa.ref.collection('registro').add({
        fecha: serverTimestamp(),
        activado: valor,
        uid: this.uid
      })
      return valor;
    } catch (e) {
      return !valor;
    }

  }

}
