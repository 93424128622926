import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FirestoreService } from './services/firestore.service';
import { LocalidadService } from './services/localidad.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'yendo-admin';

  loggedIn: boolean = false;
  isAdmin: boolean;
  cargando: boolean;

  constructor(
    private afAuth: AngularFireAuth,
    private firestoreService: FirestoreService,
    private localidadService: LocalidadService
  ) { }

  ngOnInit(): void {
    this.cargando = true;
    this.afAuth.onAuthStateChanged((user: firebase.default.User) => {
      try {
        console.log(user.uid);
        this.firestoreService.uid = user.uid;
        this.firestoreService.getCurrentUser(user.uid).subscribe((_user) => {
          this.localidadService.localidad = [_user.payload.get('localidadActual')];
          this.isAdmin = _user.payload.get('admin') != null ? true : false;
          user.getIdTokenResult().then((idTokenResult) => {
            this.firestoreService.permisos = idTokenResult?.claims?.permisosAdmin ?? [];
          });
          this.loggedIn = true;
          this.cargando = false;
        })
      } catch {
        this.loggedIn = false;
        this.cargando = false;
      }
    });
  }

  logOut() {
    this.afAuth.signOut();
    location.reload();
  }
}
