import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalidadService {

  localidad: string[];

  constructor() { }
  
}
